html,body,#root, #wrapper { height: 100% }

:root {
  --sbr-blue: #38A5B2;
  --middle-cyan: #2E8793;
  --middle-green: #31B342;
  --middle-red: #DE511E;
  --dark-red: #B74218;
  --middle-gray: #4C566A;
  --dark-gray: #2E3440;
  --gray-blue: #5E81AC
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* overide spectre */

h1 { color: var(--dark-gray) !important; }
h2 { color: var(--gray-blue) !important; }
h3, h4, h5 { margin: 5px 0 !important; }
p { margin: 0.75em 0 !important; }
a:focus{ box-shadow: none !important; }
a, a:hover, a:visited { color: var(--middle-gray); }
.toast {padding: 0 .5em !important;}

.btn-primary {
  background: var(--middle-red) !important;
  border: var(--middle-red) !important;
  border-radius: 5px !important;
}

.btn-primary:hover {
  background: var(--dark-red) !important;
  border: var(--dark-red) !important;
}

.text-gray {
  color: var(--middle-gray) !important;
}

.form-input:focus,
.form-select:focus {
  border-color: var(--sbr-blue)!important;
}

.form-checkbox input:checked+.form-icon,
.form-radio input:checked+.form-icon,
.form-switch input:checked+.form-icon {
  background: var(--middle-cyan) !important;
  border-color: var(--middle-cyan) !important;
}

code {
  margin: 5px 0 !important;
}
/* end overide */

#wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.is-error {
  border-color: var(--dark-red);
  color: var(--dark-red);
}

.is-success{
  border-color: var(--middle-green);
  color: var(--middle-green);
}

div.toast {
  margin: 0.25em 0;
}

.header-column {
  display: flex;
  justify-content: center;
}

.logo {
  background: url(../images/sbr-wonen.png) no-repeat;
  background-size: 120px 100px;
  height: 120px;
  color: white;
  font-weight: 600;
  font-size: 2.875rem;
  padding-left: 120px;
  margin: 30px;
}

.card {
  text-align: center;
  margin: 30px;
  height: calc(100% - 60px);
  max-width: 450px;
  flex: 1;
  justify-content: space-between;
}

.cards-holder .card-body {
  max-height: 75px;
}

.cards-holder {
  display: flex;
  justify-content: center;
}

/* Two equal columns next to each other */
.cards-holder-column {
  flex: 50%;
}

.form-container {
  width: 550px;
  margin: 5px auto;
}

.main-content-container {
  flex: 1 0 auto;
}

.table-container {
  margin: 75px 0;
}

.form-button {
  margin: 5px 0
}

.summary-table-heading {
  margin: 20px;
}

td.table-data {
  text-align: center;
}

.clickable-text-area {
  margin: 20px 0;
  cursor: pointer;
}

.clickable-text-area i {
  margin: .5em;
}

.scroller {
  white-space: nowrap;
  max-width: 976px;
  overflow-x: scroll;
}

.copied {
  color: var(--middle-cyan);
  margin-left: 0.5em;
}

.info-link-container {
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
}

.info-link {
  text-align: center;
  color: var(--middle-gray);
}

.disclaimer-link {
  text-align: center;
  color: var(--middle-gray);
}

.underlined {
  border-bottom: 1px solid var(--middle-red);
}

.strike-through {
  text-decoration: line-through;
}

.my-tabs {
  margin-bottom: 10px;
}

.section-table {
  min-width: 5em;
}

.warning {
  color: var(--dark-red);
}

#footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
  flex-shrink: 0;
  justify-content: flex-end;
  padding-bottom: 2em;
}

#disclaimer-ul {
  list-style: outside;
  margin-left: 60px;
}

#sbr-text {
  color: var(--sbr-blue);
}

.table-title {
  color: var(--dark-gray);
  font-weight: bold;
  text-decoration: none;
}


.table-abm table {
    table-layout: fixed;
    width: 60%;
    white-space: normal;
    margin-bottom: 50px;
}

.table-abm80 table {
  table-layout: fixed;
  width: 80%;
  white-space: normal;
  margin-bottom: 50px;
}


.table-abm2 table {
    table-layout: fixed;
    width:100%;
    white-space: normal;
    margin-bottom: 50px;
}

.table-abm table-abm2 td {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
.divTable {
    display: table;
    table-layout: fixed;
    width: 100%;
    white-space: normal;
    margin-bottom: 50px;
}

.divTr {
    display: table-row;
}

.divTd {
    display: table-cell;
    padding: .6rem .4rem;
    vertical-align: middle;
    align-content: center;
}

.divTd:not(:empty) {
    border-bottom: .05rem solid #dadee4;
}

.centerByBlock {
    display: block;
    margin: auto;
}
